import firmFoundationsIcon from './images/firm-foundations.svg'
import teamExperienceIcon from './images/team-experience.svg'
import emsoTodayIcon from './images/emso-today.svg'

export const heroData = {
  title: 'About',
  header:
    'Emso is a specialist asset manager, seeking to deliver positive risk managed returns on fixed income investments in emerging markets.',
  content: [
    "Emso's team combines decades of experience with a research-driven investment culture, analyzing the political, legal, economic, and technical aspects of each investment to build portfolios for Emso's investors.",
    "The team supplements frequent travel to investment markets with a global network, which has been developed over many decades. Emso's research and risk management are aimed at identifying opportunities, scrutinizing them, and checking and re-checking their investment premise.",
    'Emso is employee-owned with offices in London, Greenwich, and Miami and incorporates a technology driven operations platform directed at delivering high quality investment solutions for its global and predominantly institutional investor base.',
  ],
}

export const boxes = [
  {
    image: firmFoundationsIcon,
    title: 'Foundations of the firm',
    content:
      'Emso was created in 2000 by a highly specialized team with deep knowledge gained in the formative years of emerging markets. The investment team viewed emerging markets as a diversified set of opportunities - not a single asset class - that required a disciplined research approach.',
  },
  {
    image: teamExperienceIcon,
    title: 'Experience of Team',
    content:
      "The members of Emso's senior investment team average over 20 years of experience investing in emerging markets. The team has built a global network of policy specialists to gain deep insight in the drivers for each opportunity.",
  },
  {
    image: emsoTodayIcon,
    title: 'Emso Today',
    content:
      'With over 50 experienced professionals in London, Greenwich, and Miami, Emso is a diversified fixed income asset manager in emerging markets. The team manages multiple strategies, including absolute return, unconstrained long only, and private credit, as well as opportunistic and tailored strategies.',
  },
]
