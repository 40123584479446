import * as React from 'react'

export default ({ image, title, content, style }) => (
  <li className={style.boxWrapper}>
    <div className={style.box}>
      <div className={style.icon}>
        <img src={image} alt="" />
      </div>
      <div className={style.contentWrapper}>
        <h2 className={style.contentHeader}>{title}</h2>
        <p className={style.content}>{content}</p>
      </div>
    </div>
  </li>
)
