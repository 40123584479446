import * as React from 'react'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'
import { section as sectionStyle, background, backgroundGradient, intro, introWrapper, introWrapperAdmin } from './Hero.module.scss'

export default class Hero extends React.Component {
  state = { hidden: true }

  onVisible() {
    this.setState({ hidden: false })
  }

  render() {
    const {
      backgroundOpacity = 1,
      className = sectionStyle,
      admin,
      backgroundPosition,
      introWrapperClassName,
      image,
      title,
      header,
      content = [],
      children = null,
      childrenInner = null,
    } = this.props

    return (
      <Waypoint onEnter={this.onVisible.bind(this)}>
        <section className={className}>
          <div
            className={background}
            style={{ backgroundPosition, opacity: backgroundOpacity, backgroundImage: image ? `url(${image})` : 'none' }}
          />
          <div className={backgroundGradient} />
          <div className={classnames(introWrapper, introWrapperClassName, { [introWrapperAdmin]: admin })} data-hidden={this.state.hidden}>
            <div className={`${intro} small-12 medium-10 large-7 xlarge-6`}>
              <h1>{title}</h1>
              <h2>{header}</h2>
              {content.map((paragraph, index) => (
                <p key={index}>
                  {paragraph &&
                    paragraph.split('\n').map((section, pIndex) => (
                      <React.Fragment key={`${index}-${pIndex}`}>
                        {section}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              ))}
            </div>
            {childrenInner}
          </div>
          {children}
        </section>
      </Waypoint>
    )
  }
}
