import { Waypoint } from 'react-waypoint'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import Hero from '../components/Hero/Hero'
import Stack from '../components/Stack/Stack'
import { heroData, boxes } from '../data/about'
import Heading from '../components/Heading/Heading'
import Boxes from '../components/Boxes/Boxes'
import companyValues from '../data/company-values'
import { MapSection } from '../components/MapSection/MapSection'
import NextSection from '../components/NextSection/NextSection'
import heroImage from './london-background.png'
import { aboutPage, history } from './about.module.scss'

export default class About extends React.Component {
  state = { historyHidden: true }

  historyVisible() {
    this.setState({ historyHidden: false })
  }
  render() {
    return (
      <Layout blueFooter={true} className={aboutPage} hasDownArrow>
        <Helmet>
          <title>About - Emso</title>
          <meta property="og:title" content="About Emso" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={`https://www.emso.com${heroImage}`} />
          <meta
            name="description"
            content="Emso is a specialist asset manager, seeking to deliver risk managed returns on fixed income investments in emerging markets."
          />
        </Helmet>
        <Hero image={heroImage} {...heroData}>
          <MapSection />
        </Hero>
        <NextSection offset={-50} />
        <Waypoint onEnter={this.historyVisible.bind(this)}>
          <div data-hidden={this.state.historyHidden} className={history}>
            <Heading title="Company History" content={['Decades of experience in', <br key={1} />, 'emerging markets investing']} />
            <Boxes content={boxes} />
          </div>
        </Waypoint>
        <NextSection offset={-150} />
        <Stack content={companyValues} />
      </Layout>
    )
  }
}
