import * as React from 'react'
import * as style from './Boxes.module.scss'
import * as threeBoxes from './ThreeBoxes.module.scss'
import * as fourBoxes from './FourBoxes.module.scss'
import * as threeLightBoxes from './ThreeLightBoxes.module.scss'
import * as fiveBoxes from './FiveBoxes.module.scss'
import Box from './Box'

export default ({ content, isLight = false }) => {
  let wrapperClassName
  let boxStyles
  switch (content.length) {
  case 3:
    wrapperClassName = style.boxes
    boxStyles = isLight ? threeLightBoxes : threeBoxes
    break
  case 4:
    wrapperClassName = style.fourBoxes
    boxStyles = fourBoxes
    break
  case 5:
    wrapperClassName = style.threeBoxes
    boxStyles = fiveBoxes
    break
  default:
    break
  }
  return (
    <ul className={wrapperClassName}>
      {content.map((box, index) => (
        <Box key={index} {...box} style={boxStyles} />
      ))}
    </ul>
  )
}
