import * as React from 'react'
import {Waypoint} from 'react-waypoint'
import * as style from './Stack.module.scss'

const Entry = class Entry extends React.Component {
  state = { hidden: true }

  onEnter() {
    this.setState({ hidden: false })
  }

  render() {
    const { image, headline, type, description } = this.props
    return (
      <Waypoint onEnter={this.onEnter.bind(this)}>
        <li data-hidden={this.state.hidden} className={style.contentWrapper} style={{ backgroundImage: `url(${image})` }}>
          <div className={style.row}>
            {headline ? <div className={style.headline}>{headline}</div> : null}
            <div className={`${style.content} medium-9 large-8 xlarge-5`}>
              <h3>{type}</h3>
              <p>{description}</p>
            </div>
          </div>
        </li>
      </Waypoint>
    )
  }
}

export default ({ content }) => (
  <section className={style.section}>
    <ul data-hidden-parent>
      {content.map((entry, index) => (
        <Entry key={index} {...entry} />
      ))}
    </ul>
  </section>
)
