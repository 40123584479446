import transparencyImage from './images/transparency.jpg'
import accuracyImage from './images/accuracy.jpg'
import industryInitiativesImage from './images/industry-initiatives.jpg'

export default [
  {
    image: transparencyImage,
    type: 'TRANSPARENCY',
    description:
      'We value transparency and want our investors to have insight into our investment process, know what our portfolios invest in, and stay informed about the rationale behind our positions.',
    headline: 'Our Values',
  },
  {
    image: accuracyImage,
    type: 'ACCURACY',
    description:
      'We have built operations and technology solutions to provide accurate and, where possible, automated settlement across our portfolios.',
  },
  {
    image: industryInitiativesImage,
    type: 'INDUSTRY INITIATIVES',
    description:
      'We are a signatory to the UN PRI and integrate Environmental, Social, and Governance factors into our investment analysis.',
  }
]
