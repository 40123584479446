import * as React from 'react'
import * as style from './Heading.module.scss'

export default ({ title, content, blue }) => {
  return (
    <div className={blue ? style.blueHeading : style.heading}>
      {title ? <h4 className={style.title}>{title}</h4> : null}
      <h3 className={style.content}>{content}</h3>
    </div>
  )
}
