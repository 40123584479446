import * as React from 'react'
import { london, greenwich, miami, pulse, pulseWrapper, content, contentWrapper, time, map, mapSection } from './MapSection.module.scss'

const CITY_GREENWICH = 'greenwich'
const CITY_MIAMI = 'miami'
const CITY_LONDON = 'london'
class City extends React.Component {
  getTime = () => {
    let timeZone
    switch (this.props.city) {
      case CITY_MIAMI:
      case CITY_GREENWICH:
        timeZone = 'America/New_York'
        break
      default:
        timeZone = 'Europe/London'
    }

    try {
      return new Date().toLocaleString('en-US', { minute: 'numeric', hour: 'numeric', timeZone })
    } catch (e) {
      // for IE users toLocalString doesn't take a timezone
      let d = new Date()
      let utc = d.getTime() + d.getTimezoneOffset() * 60000
      const timeZoneOffset = this.props.city === CITY_GREENWICH ? -5 : 0
      return new Date(utc + 3600000 * timeZoneOffset).toLocaleTimeString('en-US', { minute: 'numeric', hour: 'numeric' })
    }
  }

  getName = () => {
    switch (this.props.city) {
      case CITY_GREENWICH:
        return 'Greenwich'
      case CITY_MIAMI:
        return 'Miami'
      default:
        return 'London'
    }
  }

  getClassName = () => {
    switch (this.props.city) {
      case CITY_GREENWICH:
        return greenwich
      case CITY_MIAMI:
        return miami
      default:
        return london
    }  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className={pulseWrapper}>
          <div className={pulse}>
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={contentWrapper}>
          <span className={content}>{this.getName()}</span>
          <span className={time}>{this.getTime()}</span>
        </div>
      </div>
    )
  }
}

export class MapSection extends React.Component {
  render() {
    return (
      <section className={mapSection}>
        <div className={map}>
          <City city={CITY_GREENWICH} />
          <City city={CITY_MIAMI} />
          <City city={CITY_LONDON} />
        </div>
      </section>
    )
  }
}
